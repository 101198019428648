.card {
  box-shadow: $card-box-shadow;

  &._full-width {
    width: 100%;
    @extend .m-0;
    @extend .p-0;
    border-radius: 0;
    position: absolute;
    left: 0;
    right: 0;
  }

  .card-header {
    padding: $card-header-padding;
  }

  .card-body {
    font-family: $font-family-sans-serif;
    padding: $card-body-padding;
  }

  &.card-plain {
    background-color: $card-plain-bg-color;
    box-shadow: $card-plain-box-shadow !important;
  }

  .card-footer {
    padding: $card-footer-padding;
    background-color: transparent;
  }
}

.author {
  display: $card-author-display;

  .name > span {
    line-height: $card-author-name-line-height;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    color: $card-author-name-color;
  }

  .stats {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
  }
}

.animation-wrapper .outer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 2rem
}

.animation-wrapper .animation-card {
  background: #fff;
  width: 430px;
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: space-between;
  border-radius: 100px 100px 100px 100px;
  box-shadow:  0px 10px 15px rgba(0,0,0,0.1);
}

.animation-card .animation-content {
  display: flex;
  align-items: center;
}

.animation-card .animation-details {
  margin-left: 40px;
  padding-top: 10px;
}

.animation-details span {
  font-weight: 600;
  font-size: 18px;
}

.animation-card a {
  text-decoration: none;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  margin-right: 20px;
  color: #fff;
  background: linear-gradient(to bottom, #bea2e7 0%, #86b7e7 100%);
  transition: all 0.3s ease;
}

.animation-card a:hover {
  transform: scale(0.94)
}


@keyframes pushCards {
  0% {
    opacity: 0.5;
    z-index: 3;
  }
  0%, 50% {
    opacity: 0.7;
  }
  50%, 100% {
    opacity: 1;
    margin-bottom: 0px;
  }
}

@keyframes pushCards2 {
  0% {
    opacity: 0.3;
    z-index: 2;
  }
  0%, 50% {
    opacity: 0.3;
  }
  50%, 100% {
    opacity: 0.3;
    margin-bottom: 10px;
  }
}

@keyframes stackCards {
  0% {
    transform: translateY(0px);
    z-index: 1;
    opacity: 1;
  }
  15%, 40% {
    transform: translateY(-25px);
    opacity: 0.7;
  }
  40%, 60% {
    transform: translateY(-50px);
    opacity: 0.5;
  }
  60%, 80% {
    transform: translateY(-25px);
    opacity: 0.3;
  }
  80%, 100% {
    transform: translateY(0px);
    margin-bottom: 20px;
    opacity: 0.3;
  }
}

.animation-card:nth-child(1) {
  z-index: 3;
}

.animation-card:nth-child(2) {
  opacity: 0.3;
  z-index: 2;
}

.animation-card:nth-child(3) {
  opacity: 0.3;
  z-index: 1;
}

.animation-card.active {
  z-index: 5;
  opacity: 1;
}



@import 'cards/card-background';
@import 'cards/card-carousel';
