

.pin-button {
    @extend .m-3;
    @extend .btn;
    @extend .btn-primary;
    @extend .text-light;
    @extend .h1;
    width: 12rem;
    height: 12rem;
    border-radius: 2rem;
  
    &-backspace {
      @extend .btn-warning;
    }
  
    &-submit {
      @extend .btn-success;
    }
  }
  
  .pin-input {
    @extend .form-control;
    @extend .text-center;
    @extend .mx-2;
    @extend .bg-light;
    font-size: 6rem;
    width: 8rem;
    height: 8rem;
  }