@each $prop, $value in $theme-colors {
  .badge.bg-#{$prop} {
    background: $value;
  }
}

.badge-clickable {
  cursor: pointer;
}
.badge:hover {
  @extend .shadow;
}
.badge {
  &.disabled:hover {
    cursor: default;
    box-shadow: none !important;
  }
}
