.select-container {
    transition: all 100ms ease 0s;
    background-color: rgb(255, 255,255) !important;
    cursor: default;
}

.value-container {
    align-items: center;
    display: grid;
    flex: 1 1 0%;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.select-box {
    z-index: 9999;
    margin-top: 1px;
    position: absolute;
    max-height: 320px;
    overflow-y: auto;
    border: 1px solid #d2d6da;
    background-color: white;
    >ul {
        >li {
            height: 20px;
            cursor: pointer;
        }
        padding-left: 0;
        margin: 0;
        position: relative;
        list-style-type: none;
    }
}

.select-item {
    font-size: 0.875rem;
    padding-left: 10px;
    &:hover{
        background-color: #3399ff;
        color: white;
    }
}

.search-input {
    position: sticky;
    top: 0;
    z-index: 999;
    padding-bottom: 10px;
}