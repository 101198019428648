.widget {
    @extend .d-flex;
    @extend .w-100;
    @extend .h-100;
    @extend .card;

    > * {
        transition: opacity 0.3s;
    }

    &-editable {
        @extend .move-on-hover;
        > * {
            pointer-events: none;
            opacity: 0.4;
        }
    }
}

.widget-remove-button {
    @extend .btn;
    @extend .btn-danger;
    @extend .d-flex;
    @extend .align-self-end;
    position: absolute;
    pointer-events: stroke;
    opacity: 1;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 100;
}
