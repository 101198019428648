// =========================================================
// * Argon Dashboard 2 Pro - v2.0.5
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/argon-dashboard-pro
// * Copyright 2022 Creative Tim (https://www.creative-tim.com)
//
// Coded by www.creative-tim.com
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
//google fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

// Bootstrap Functions
@import "argon-dashboard/bootstrap/functions";

// Custom Variables
@import "argon-dashboard/custom/variables";

// Theme Variables
@import "argon-dashboard/variables";

// Bootstrap Core
@import "argon-dashboard/bootstrap/bootstrap";

// Theme Core
@import "argon-dashboard/theme-pro";

// Custom Variables
@import "argon-dashboard/custom/styles";

:root {
    --toastify-color-success: #2dce89;
    --toastify-color-light: #e9ecef;
    --toastify-color-dark: #12354b;
    --toastify-color-info: #41b9c1;
    --toastify-color-warning: #fb6340;
    --toastify-color-error: #f5365c;
}

* {
    font-family: "Montserrat";
    scroll-behavior: smooth;
}
body {
    font-family: "Montserrat";
}