.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-prompt {
  @extend .card;
  display: flex;
  position:fixed;
  margin: 0;
  padding: 0;
  width: 50%;
  height: auto;
  max-height: 100vh;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.modal-mobile-prompt {
  @extend .card;
  @extend .pt-3;
  @extend .px-2;
  @extend .w-100;
  max-width: 95%;
  display: flex;
  position: fixed;
  max-height: 100vh;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);

}

.modal-mobile-bottom {
  @extend .card;
  @extend ._full-width;
  @extend .pt-3;
  @extend .px-2;
  display: flex;
  position:fixed;
  margin: 0;
  height: 90%;
  bottom: 0;
  left: 0;
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
  animation: slide-up 0.3s ease-in-out forwards;

  &.hide {
    animation: slide-down 0.3s ease-in-out forwards;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%); /* Start from off-screen bottom */
  }
  to {
    transform: translateY(0); /* End at the bottom of the screen */
  }
}

@keyframes slide-down {
  from {
    transform: translateY(0); /* Start at the bottom of the screen */
  }
  to {
    transform: translateY(100%); /* End off-screen at the bottom */
  }
}

.modal-pane {
  @extend .card;
  border-radius: 0px;
  position:fixed;
  display: flex;
  margin: 0;
  padding: 0;
  width: 45%;
  height: 100%;
  right: 0;
}


.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal-body{
  @extend .card-body;
  max-height: 600px;
  min-height: 100px;
  overflow-y: visible;
  &::-webkit-scrollbar{
    display: none;
  }
  &.static {
    overflow-y: unset;
  }
}

.modal-pane > .modal-body{
  max-height: 100vh;
  
}

body:has(.modal.display-block) {
  overflow:hidden;
  padding-right: 15px;

}

.overflow-y-unset {
  overflow-y: unset;
}